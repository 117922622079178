import React from 'react'

const Contactus = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row bg_image">
          <div className="col-md-12 p-5">
            <h3>Contact us</h3>
          </div>
        </div>
      </div><br />
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h3>CONTACT OUR OFFICE</h3>
            <p>If you have any querry for related Consulting… Our support available 24/7, Feel free to contact .</p>
            <h4>Servies+ Civil Contractors and Interior Designers in Kharghar</h4>
            <div className='d-flex'>
              <div className="col">
                <br/>
                <h3>Main</h3>
                <p>
                  Kharghar Sector-12 Near Prime Mall<br/>Navi Mumbai Pincode-410210
                </p>
              </div>
              <div className="col">
                <br/>
                <h3>Call Us</h3>
                <p>+91 7666008182</p>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <h2 class="text-center">Send a Message</h2>
            <form>
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" placeholder="Enter your name" required />
              </div>
              <div class="form-group">
                <label for="email">Email address</label>
                <input type="email" class="form-control" id="email" placeholder="Enter your email" required />
              </div>
              <div class="form-group">
                <label for="phone">Phone Number</label>
                <input type="tel" class="form-control" id="phone" placeholder="Enter your phone number" required />
              </div>
              <div class="form-group">
                <label for="message">Message</label>
                <textarea class="form-control" id="message" rows="4" placeholder="Type your message here" required></textarea>
              </div>
              <button type="submit" class="btn btn-success btn-block mt-2">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <br/><br/><br/>

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10 d-flex justify-content-center">
            <div style={{ position: 'relative', width: '100%', maxWidth: '1600px', height: '500px', overflow: 'hidden' }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10418.787078671086!2d73.06107512558548!3d19.042552395111212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c2164dbbc321%3A0xb60a32a655d291de!2sSector%2012%2C%20Kharghar%2C%20Navi%20Mumbai%2C%20Maharashtra%20410210!5e0!3m2!1sen!2sin!4v1725018951182!5m2!1sen!2sin"
                frameBorder="0"
                style={{ border: '10', height: '80%', width: '100%' }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Contactus