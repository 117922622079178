import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
const Header = () => {
    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <Link class="navbar-brand" to={"/"}>PRIME RENOVATIONS</Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{ justifyContent: 'space-between' }}>
                        <div className="menus">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item active">
                                    <Link to={"/"} class="nav-link" >HOME</Link>
                                </li>
                                <li class="nav-item dropdown">
                                    <Link to={"services"} className="nav-link dropdown-toggle" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        SERVICES
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                                        <li><Link to={"CivilContractor"} className="dropdown-item">Civil contractor in Navi-Mumbai</Link></li>
                                        <li><Link to={"Interiordeisgn"} className="dropdown-item">Interior Designer & Home Decor</Link></li>
                                        <li><Link to={"Flooring"} className="dropdown-item">Flooring and Tiles work In Navi-Mumbai</Link></li>
                                        <li><Link to={"BathroomDesign"} className="dropdown-item"> Bathroom Designer in Kharghar, Mumbai, and Navi-Mumbai.</Link></li>
                                        <li><Link to={"PaintingWork"} className="dropdown-item"> Painting work in Kharghar, Mumbai, Navi-Mumbai.</Link></li>
                                        <li><Link to={"Grillwork"} className="dropdown-item"> Sliding/Frame/Grill work in Kharghar, Mumbai & Navi-Mumbai..</Link></li>
                                        <li><Link to={"Furniturework"} className="dropdown-item"> Furniture In Kharghar</Link></li>
                                        <li><Link to={"Popfalseceiling"} className="dropdown-item"> POP Work & False Ceiling</Link></li>

                                    </ul>
                                </li>

                                <li>
                                    <Link to={"about"} class="nav-link" >ABOUT US</Link>
                                </li>
                                <li>
                                    <Link to={"portfolio"} class="nav-link" >PORTFOLIO</Link>
                                </li>

                                <li>
                                    <Link to={"contactus"} class="nav-link" >CONTACT US</Link>
                                </li>
                                <li>
                                    <Link to={"feedback"} class="nav-link" >FEEDBACK</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <a href="#" className='social_icons'><FaFacebookF /></a>
                            <a href="#" className='social_icons'><FaInstagram /></a>
                            <a href="#" className='social_icons'><FaXTwitter /></a>
                            <a href="#" className='social_icons'><FaYoutube /></a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header