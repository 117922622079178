import React from 'react'
import { Link } from 'react-router-dom';

const servicesData = [
  {
    id: 1,
    image: "../images/services1.jpg",
    title: "Civil contractor in Navi-Mumbai",
    linking:"CivilContractor",
    description: "We offer services for – Civil contractors in Kharghar, Navi-Mumbai & Mumbai.",
  },
  {
    id: 2,
    image: "../images/services2.jpg",
    title: "Interior Designer & Home Decor",
    linking:"Interiordeisgn",
    description: "We offer services for – Interior designers in Kharghar, Mumbai & Navi-Mumbai.",
  },
  {
    id: 3,
    image: "../images/services3.jpg",
    title: "Flooring and Tiles work In Navi-Mumbai",
    linking:"Flooring",
    description: "We offer all types of Tiles and flooring work for your flat, house, or bungalow.",
  },
  {
    id: 4,
    image: "../images/services4.jpg",
    title: "Bathroom Design",
    linking:"BathroomDesign",
    description: "We offer one of the best Bathroom Designer in Kharghar, Mumbai, and Navi-Mumbai.",
  },
  {
    id: 5,
    image: "../images/services5.jpg",
    title: "Painting Work",
    linking:"PaintingWork",
    description: "We offer services for – Painting work in Kharghar, Mumbai, Navi-Mumbai.",
  },
  {
    id: 6,
    image: "../images/services6.jpg",
    title: "Sliding/Frame/Grill work",
    linking:"Grillwork",
    description: "We offer services for – Sliding/Frame/Grill work in Kharghar, Mumbai & Navi-Mumbai.",
  },
  {
    id: 7,
    image: "../images/services7.jpg",
    title: "Furniture work",
    linking:"Furniturework",
    description: "We offers Furniture In Kharghar, Mumbai & Navi-Mumbai.",
  },
  {
    id: 8,
    image: "../images/gallery1.jpg",
    title: "POP Work & False Ceiling",
    linking:"Popfalseceiling",
    description: "We offers POP Work & False Ceiling in Navi-Mumbai.",
  }
];

const Sectiontwo = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 style={{ textAlign: 'center', padding: "10px" }}>Prime Renovations : Civil Contractors, Home Renovation & Interior Designers
                In Kharghar,Mumbai and Navi-Mumbai</h3>
              <p>We at Prime Renovations are one of the best civil contractors in Kharghar for Interior Designer in Kharghar, Home Renovation, Kitchen Modification, and other related Civil Contractors in Kharghar to make your house a place to live with harmony and pleasure.
                <br /><br />
                At Prime Renovations we take all kinds of civil work and interior design that includes flooring tiling, plumbing, false ceiling, bathroom design and renovation, and kitchen remodeling work with the best quality materials.
                <br /><br />
                Interior Designer in Navi Mumbai: Our Interior Design services in Kharghar are designed to turn your dreams into reality. Whether it’s your home, office, or commercial space, our experienced interior designers are dedicated to creating stunning interiors that reflect your style and personality. From conceptualization to execution, we pay meticulous attention to every detail, ensuring a space that’s not only visually appealing but also functional and practical.
                <br /><br />
                Civil Contractor in Kharghar & Navi Mumbai: When it comes to Civil Contracting services in Kharghar and Navi Mumbai, we stand out as leaders in the industry. Our skilled team of civil contractors brings extensive experience to the table, delivering projects with utmost precision and excellence. We specialize in a wide range of civil contracting services including construction, renovation, remodeling, and more. No project is too big or too small for us – we are committed to delivering top-quality results on time and within budget.
              </p>

            </div>
          </div><br /><br />
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="card">
                <div className="card-header">
                  <img src="../images/image3.jpg" alt="image not found" className="image_width" />
                </div>
                <div className="card-body">
                  <h4>Civil Contractors</h4>
                  <p>Specialized capabilities to help clients face the challenges of today</p>
              {/* <button className="btn btn-primary">Read more</button>*/}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="card-header">
                  <img src="../images/image5.jpg" alt="" className="image_width" />
                </div>
                <div className="card-body">
                  <h4>Home Renovation</h4>
                  <p>Significant value by providing security solutions across industry verticals</p>
                 {/* <button className="btn btn-primary">Read more</button>*/}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="card-header">
                  <img src="../images/image4.jpg" alt="" className="image_width" />
                </div>
                <div className="card-body">
                  <h4>Interior Designer</h4>
                  <p>Opportunities while benefiting those without access to financial services</p>
                  {/*<button className="btn btn-primary">Read more</button>*/}
                </div>
              </div>
            </div>
          </div>
          <br /><br />
        </div>


        <div className="container-fluid">
          <div className="row bring_bg  p-5">
            <h1 className='text-center'>Bring Home Beautiful Interiors That Fit Your Budget</h1>
            <div className="row">
              <div className="col-md-4 content_count">
                <div className="row"><h3><strong>100+</strong></h3></div>
                <div className="row">Happy Customer</div>
              </div>
              <div className="col-md-4 content_count">
                <div className="row"><h3><strong>150+</strong></h3></div>
                <div className="row">Completed Work</div>
              </div>
              <div className="col-md-4 content_count">
                <div className="row"><h3><strong>5+</strong></h3></div>
                <div className="row">Process Work</div>
              </div>
            </div>
          </div><br /><br />
        </div>

        <div>
          <h1 className='text-center'>OUR SERVICES</h1>
          <p className='text-center'>Prime Renovations – Civil Contractors and Interior Renovators<br /> in Kharghar, Prime Mall Road, Mumbai And Navi Mumbai</p>
          <br />
        </div>
        <div className="container">
          <div className="row">
            {servicesData.map((service) => (
              <div className="col-md-3" key={service.id}>
                <div className="card">
                  <div className="card-header">
                    <img src={service.image} alt={service.title} className="image_width" />
                  </div>
                  <div className="card-body">
                    <h4>{service.title}</h4>
                    <p>{service.description}</p>
                   <Link to={service.linking}> <button className="btn btn-primary">Read more</button></Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div><br /><br /><br />

        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3>CONTACT OUR OFFICE</h3>
              <p>If you have any querry for related Consulting… Our support available 24/7, Feel free to contact .</p>
              <h4>Servies+ Civil Contractors and Interior Renovators</h4>
              <div className='d-flex'>
                <div className="col"><br />
                  <h3>Main</h3>
                  <p>
                    Kharghar Sector-12 Near Prime Mall<br />Navi Mumbai Pincode-410210
                  </p>
                </div>
                <div className="col"><br />
                  <h3>Call Us</h3>
                  <p>+91 7666008182</p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h2 class="text-center">Send a Message</h2>
              <form>
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" id="name" placeholder="Enter your name" required />
                </div>
                <div class="form-group">
                  <label for="email">Email address</label>
                  <input type="email" class="form-control" id="email" placeholder="Enter your email" required />
                </div>
                <div class="form-group">
                  <label for="phone">Phone Number</label>
                  <input type="tel" class="form-control" id="phone" placeholder="Enter your phone number" required />
                </div>
                <div class="form-group">
                  <label for="message">Message</label>
                  <textarea class="form-control" id="message" rows="4" placeholder="Type your message here" required></textarea>
                </div>
                <button type="submit" class="btn btn-success btn-block mt-2">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div><br /><br />
    </>
  )
}

export default Sectiontwo