import React from 'react';

const Interiordeisgn = () => {
  return (
    <div className="container">

<div className="container">
      {/* Image at the top */}
      <img 
        src="../images/services2.jpg"
        alt="Prime Renovations Interior Design" 
        className="img-fluid mb-4"
      />
      </div>
      <h1>Interior Designer in Kharghar, Navi Mumbai – Full Home Renovation Work</h1>
      <p>
        At Prime Renovations, we offer full home renovation and interior design work that make your home look like the real home of your dreams.
      </p>
      <p>
        We at Prime Renovations are one of the best civil contractors in Kharghar for interior designers, home renovation, modular kitchens, kitchen modification, bathroom interiors, and other related civil works to make your house a place to live with harmony and pleasure.
      </p>
      <p>
        We offer services for – Interior designers in Navi Mumbai & Kharghar, civil contractors in Kharghar, painting work in Navi Mumbai & Kharghar, false ceiling work, flooring work in Kharghar, tiles fixing & installation work in Kharghar, bathroom interior in Navi Mumbai & Kharghar, and modular kitchen work in Kharghar.
      </p>
      <p>
        We have helped more than 1000+ customers in Kharghar, Navi Mumbai, and Mumbai in renovating their conventionally designed homes into contemporary establishments by using the latest designs, wooden work, furniture, and other related work for home renovation and interior designing. This includes a set of integrated tasks starting from initial civil work, construction, plastering, flooring, tiles fixing, wall tiling, painting, POP work, gypsum false ceiling, kitchen redesigning, modular kitchen setup, trolley installation in the kitchen, bedroom redesigning, wooden work like bed, wardrobe construction, window work, grilling for windows and doors, and much more. Being one of the most prominent residential interior designers in Kharghar, we are here to equip our clients with a holistic solution, covering all the nitty-gritty involved in enhancing the aesthetics and functionality of your home.
      </p>
      <p>
        We provide all solutions for indoor, outdoor, bathroom, and commercial flooring. We suggest flooring according to the room to make it best in view and look as recommended by the customer. In our plumbing service, we include the best quality material for piping and fittings. We provide sanitary fittings from leading brands with the best finishes that will suit interior concepts. We always try to introduce new technologies that are more comfortable and help you to have good health and live in harmony in your dream home.
      </p>
      <p>
        These are a few of our interior designing and home renovation services that we undertake for making your house, flat, or bungalow beautiful, functional, and comfort-oriented. Thus, if you are in pursuit of an adroit interior designer in Navi Mumbai or Kharghar, then look no further than Prime Renovations.
      </p>
      <h2>Ready to unlock the doors to your dream house with the best interior designers in Kharghar and Navi Mumbai?</h2>
    </div>
  );
}

export default Interiordeisgn;
