import React from 'react';

const BathroomDesign = () => {
  return (
    <div className="container">
      {/* Image at the top */}
      <img 
        src="../images/services4.jpg"  
        alt="Prime Renovations Bathroom Design" 
        className="img-fluid mb-4"
        style={{ width: '100%', height: '600px', maxWidth: '1200px' }} 
      />

      <h1>Bathroom Design in Kharghar, Navi Mumbai & Mumbai</h1>
      <p>
        At Prime Renovations, we specialize in full home renovation and interior design work, transforming your home into the space of your dreams. Our expertise extends to creating beautifully designed and functional bathrooms, ensuring they add both style and comfort to your home.
      </p>
      <p>
        As one of the leading civil contractors in Kharghar, we offer comprehensive services including interior design, home renovation, kitchen modification, and more. We focus on making your house a harmonious and pleasurable place to live.
      </p>
      <p>
        The bathroom is a vital part of your home, and it's essential to design it to be both beautiful and functional. A well-designed bathroom adds a refreshing touch to your home and enhances your daily comfort.
      </p>
      <p>
        Modern bathrooms often serve dual purposes, combining functionality with aesthetic appeal. We offer a variety of design options, including organic shapes and color combinations. For instance, a classic coral color scheme—featuring rosewood, coral, and fuchsia—can create a vibrant and fresh atmosphere.
      </p>
      <p>
        With Prime Renovations, you can design your bathroom tiles, floor plans, and more in just a few minutes. Bathrooms and toilets are essential in any home or office, and any issues such as leaks or outdated fixtures need prompt attention. We offer renovation and repair services for your bathrooms in Kharghar, Navi Mumbai, and Mumbai. Our experienced team can handle any problems, ensuring a hassle-free experience.
      </p>
      <p>
        Common issues in residential and commercial toilets include continuously running toilets, which can lead to water wastage and other problems. Our contractors are skilled in addressing these issues, including replacing flapper valves and diagnosing water supply valve problems. We ensure minimal disruption and high-quality service.
      </p>
      <p>
        Contact us today to discuss your bathroom renovation needs. Reach out to us at <strong>7666008182</strong> or email us at <strong>dipendrasingh8182@gmail.com</strong>.
      </p>
    </div>
  );
}

export default BathroomDesign;
