import React from 'react';

const FlooringTiles = () => {
  return (
    <div className="container">
      {/* Image at the top */}
      <img 
        src="../images/services3.jpg"
        alt="Prime Renovations Flooring and Tiles Work" 
        style={{ width: '50%', height: 'auto', maxWidth: '1200px' }} 
        className="img-fluid mb-4"
      />

      <h1>Flooring and Tiles Work in Kharghar, Navi Mumbai & Mumbai</h1>
      <p>
        At Prime Renovations, we offer full home renovation and interior design services that transform your living spaces into the home of your dreams. Whether you’re updating your flooring or tiling, our team ensures that every detail is handled with care and precision.
      </p>
      <p>
        We at Prime Renovations are among the best civil contractors in Kharghar for interior design, home renovation, kitchen modification, and related services, making your house a harmonious and pleasurable place to live.
      </p>
      <p>
        Flooring and tiling, especially in areas where you spend most of your time, face various challenges such as wear and tear, furniture loads, abrasion, chemical cleaning, and more. This makes it essential to choose high-quality materials for both residential and commercial spaces. The durability, comfort, and cost of the flooring material are key factors to consider before making a choice.
      </p>
      <p>
        If you're seeking a solution that meets these criteria, Prime Renovations is the top choice for tiles, flooring, and marble work contractors in Kharghar, Navi Mumbai, and Mumbai. Our skilled interior designers will surprise you with their creative ideas and designs. We provide tile and flooring services for residential construction, commercial construction, home improvement, remodeling, or any other repair projects. The Prime Renovations team executes all projects with our clients’ requirements in mind, delivering solutions aligned with the latest market trends.
      </p>
      <p>
        At Prime Renovations, we are licensed and insured tile and flooring contractors in Kharghar, Navi Mumbai, and Mumbai. We meticulously study the project site area to suggest the most suitable tile size, color, and design, as tiles come in various options.
      </p>
      <p>
        Contact us today and give us the opportunity to design your house, office, or hotel. You can reach us at <strong>7666008182</strong> or email us your requirements at <strong>dipendrasingh8182@gmail.com</strong>.
      </p>
    </div>
  );
}

export default FlooringTiles;
