import React from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from 'react-router-dom';

const Subheader = () => {
    return (
        <>
            <div className='container'>
                <div className='row pt-4 pb-4'>
                    <div className='col-md-3'>
                    <Link class="navbar-brand" to={"/"}><img src="../images/logo3.png" alt='Logo'
                    style={{ width: '200px', height: '140px' ,marginTop: '-40px' }} /></Link>
                    </div>
                    <div className='col-md-3 d-flex'>
                        <div className="col d-flex justify-content-center"><FaPhoneAlt className='sub_header_icon'/></div>
                        <div className="col">
                            <div className="row">Any quries? Call us
                            </div>
                            <div className="row">+91 7666008182</div>
                        </div>
                    </div>
                    <div className='col-md-3 d-flex'>
                        <div className="col d-flex justify-content-center"><MdEmail  className='sub_header_icon'/></div>
                        <div className="col">
                            <div className="row">Any quries? Mail us.
                            </div>
                            <div className="row">dipendrasingh8182@gmail.com</div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                       <Link to="contactus"> <button className='custom-button'>GET A QUOTE</button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subheader