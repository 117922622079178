import React from 'react'
import Contactus from './Contactus'

const Portfolio = () => {
    return (
        <><div className="container-fluid">
            <div className="row bg_image">
                <div className="col-md-12 p-5">
                    <h1>Portfolio</h1>
                </div>
            </div>
        </div><br /><br/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Completed Work</h2>
                    </div>
                </div><br/><br/>
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <img src="../images/gallery1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="img not found" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <img src="../images/image1.jpg" alt="" style={{width:'100%'}}/>
                    </div>
                    <div className="container">
                    </div>
                    <br/><br/><br/>
        <div className="row">
          <div className="col-md-7">
          
        </div>
    </div>
   </div>
   </div><br/><br/>
        </>
    )
}

export default Portfolio