import React from 'react'
import Sectionone from './Sectionone'
import Sectiontwo from './Sectiontwo'

const Home = () => {
  return (
    <>
    <Sectionone/>
    <Sectiontwo/>
    </>
  )
}

export default Home