import React from 'react';

const FurnitureServices = () => {
  return (
    <div className="container">
      {/* Image at the top */}
      <img 
        src="../images/services7.jpg"  
        alt="Prime Renovations Furniture Services" 
        className="img-fluid mb-4"
        style={{ width: '100%', height: 'auto', maxWidth: '1200px' }} 
      />

      <h1>Furniture Services in Kharghar, Navi Mumbai & Mumbai</h1>
      <p>
        At Prime Renovations, we offer comprehensive furniture services in Kharghar, Navi Mumbai, and Mumbai. Our expertise in full home furniture work and remodeling ensures your home achieves the perfect blend of modern design and functionality.
      </p>
      <p>
        Beyond interior designing, we provide high-quality furnishing solutions with exceptional woodwork and design. Our experienced team of interior designers is dedicated to crafting the ideal look for your living room, bedroom, kitchen, or kids' room, tailored to meet your specific requirements.
      </p>
      <p>
        As an award-winning interior design studio, we pride ourselves on modern furniture designs that reflect both style and substance. Quality and design are at the forefront of our mission. We aim to create beautiful, affordable, and sustainable furniture that combines comfort with efficiency.
      </p>
      <p>
        Our skilled professionals are committed to delivering furniture that meets the highest standards. We operate a full production facility and work closely with our suppliers to ensure premium-quality products. Our furniture design solutions are developed and delivered by a team of creative experts, blending technology, design, and business acumen to create a luxurious experience.
      </p>
      <p>
        We focus on producing modern furniture that is both aesthetically pleasing and environmentally friendly. Our processes prioritize sustainability, using the best materials and practices to craft furniture that aligns with our ethical values and meets high demand in a timely manner.
      </p>
      <p>
        Contact us today to explore our furniture design solutions and discover how Prime Renovations can transform your home with elegant and functional furnishings.
      </p>
      <p>
        Reach out to us at <strong>7666008182</strong> or email us at <strong>dipendrasingh8182@gmail.com</strong>.
      </p>
    </div>
  );
}

export default FurnitureServices;
