import React from 'react';

const Footer = () => {
  return (
    <>
      <footer style={{ backgroundColor: 'rgb(170, 207, 223)', color: 'black', padding: '20px 0' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-4">
              <h6 style={{ fontWeight: 'bold' }}>Prime Renovations</h6>
              <p>
                Prime Renovations is one of the best civil contractors and Interior designers in Kharghar for Interior Design, Home Renovation, Kitchen Modification, and other related Civil Contracting services in Kharghar, Mumbai, and Navi Mumbai.
              </p>
              <p>
                <strong>Need Help? Call us now:</strong><br />
                +91 7666008182
              </p>
              <p>
                <strong>Mail us now:</strong><br />
                <a href="mailto:dipendrasingh8182@gmail.com" style={{ color: 'black', textDecoration: 'none' }}>
                  dipendrasingh8182@gmail.com
                </a>
              </p>
            </div>
            <div className="col-md-4 mb-4">
              <h6 style={{ fontWeight: 'bold' }}>NEWS & EVENTS</h6>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#" style={{ color: 'black', textDecoration: 'none' }}>
                    Unveiling Excellence: Interior Designers, Renovation Tips, and Modular Kitchen Solutions in Kharghar
                  </a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#" style={{ color: 'black', textDecoration: 'none' }}>
                    Are you tired of Your Home? Breathe New Life into it with Prime Renovations, Mumbai’s Top Home Renovation Experts!
                  </a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#" style={{ color: 'black', textDecoration: 'none' }}>
                    Unveiling the Magic: How Can an Interior Designer in Kharghar Transform My Living Room?
                  </a>
                </li>
                <li>
                  <a href="#" style={{ color: 'black', textDecoration: 'none' }}>
                    Craft Masterpieces with Interior Design Gurus: Creating Innovative Home Renovation In Mumbai & Kharghar
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 mb-4">
              <h6 style={{ fontWeight: 'bold' }}>CONTACT US</h6>
              <p>
                <a href="mailto:dipendrasingh8182@gmail.com" style={{ color: 'black', textDecoration: 'none' }}>dipendrasingh8182@gmail.com</a>
              </p>
              <p>
                <a href="tel:+917666008182" style={{ color: 'black', textDecoration: 'none' }}>+91 7666008182</a>
              </p>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', padding: '10px', backgroundColor: 'black', color: 'white' }}>
          © 2024 Prime Renovations. All rights reserved.
        </div>
      </footer>
    </>
  );
};

export default Footer;
