import React from 'react'
import './Style.css'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { Routes } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Components/Home'
import Services from './Components/Services'
import Aboutus from './Components/Aboutus'
import Portfolio from './Components/Portfolio'
import Contactus from './Components/Contactus'
import Feedback from './Components/Feedback'
import Subheader from './Components/Subheader'
import CivilContractor from './Components/Services/CivilContractor'
import Interiordeisgn from './Components/Services/Interiordeisgn'
import Flooring from './Components/Services/Flooring'
import BathroomDesign from './Components/Services/BathroomDesign'
import Paintingwork from './Components/Services/Paintingwork'
import Grillwork from './Components/Services/Grillwork'
import Furniturework from './Components/Services/Furniturework'
import Popfalseceiling from './Components/Services/Popfalseceiling'


const App = () => {
  return (
    <>
      <Router>
        <Subheader/>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/about' element={<Aboutus/>}/>
          <Route path='/portfolio' element={<Portfolio/>}/>
          <Route path='/contactus' element={<Contactus/>}/>
          <Route path='/feedback' element={<Feedback/> }/>
          <Route path='/CivilContractor' element={<CivilContractor/>}/>
          <Route path='/Interiordeisgn' element={<Interiordeisgn/>}/>
          <Route path='/Flooring' element={<Flooring/>}/>
          <Route path='/BathroomDesign' element={<BathroomDesign/>}/>
          <Route path='/Paintingwork' element={<Paintingwork/>}/>
          <Route path='/Grillwork' element={<Grillwork/>}/>
          <Route path='/Furniturework' element={<Furniturework/>}/>
          <Route path='/Popfalseceiling' element={<Popfalseceiling/>}/>
        </Routes>
        <Footer/>
      </Router>
    </>
  )
}

export default App