import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ReviewPage = () => {
  const [reviews, setReviews] = useState([
    {
      name: 'Ravi Kumar',
      date: 'August 5, 2023',
      rating: 5,
      comment: 'I had my living room renovated by Prime Renovations, and the results were fantastic. The team was very professional and the attention to detail was impressive. Highly recommended for quality work!',
    },
    {
      name: 'Anita Sharma',
      date: 'July 29, 2023',
      rating: 5,
      comment: 'Prime Renovations did an outstanding job on our kitchen remodel. The work was completed on time and within budget. The team was courteous and attentive to our needs. We’re thrilled with the outcome!',
    },
    {
      name: 'Rajesh Patel',
      date: 'June 15, 2023',
      rating: 5,
      comment: 'Our bathroom renovation by Prime Renovations was exceptional. The team delivered exactly what we wanted and handled all aspects of the job with professionalism. The end result was better than we imagined.',
    },
    {
      name: 'Sonia Mehta',
      date: 'May 10, 2023',
      rating: 5,
      comment: 'Prime Renovations did an amazing job on our home renovation. The project was completed on time, and the quality of work was top-notch. The team’s attention to detail was commendable.',
    },
  ]);

  useEffect(() => {
  }, []);

  return (
    <div className="container my-5">
      <div className="text-center mb-5">
        <h1 className="display-3 fw-bold">Client Reviews</h1>
        <p className="text-muted fs-3">Here’s what our clients have to say about Prime Renovations.</p>
      </div>

      <div className="row">
        {reviews.map((review, index) => (
          <div key={index} className="col-md-6 mb-4">
            <div className="p-4 bg-light rounded-3 shadow-lg">
              <h4 className="fw-bold mb-1">{review.name}</h4>
              <small className="text-muted d-block mb-2">{review.date}</small>
              <div className="text-warning mb-3">
                {[...Array(review.rating)].map((_, i) => (
                  <i key={i} className="fas fa-star"></i>
                ))}
              </div>
              <p className="text-muted">{review.comment}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewPage;
