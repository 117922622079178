import React from 'react'

const Aboutus = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row bg_image">
          <div className="col-md-12 p-5">
            <h3>About us</h3>
          </div>
        </div>
      </div><br />
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <img src="../images/gallery1.jpg" alt="" style={{ width: '100%' }} />
          </div>
          <div className="col-md-5 bg_about_page">
            <div className="row">
              <div className="col-3"><br/>
                <img src="../images/icon1.png" alt="" style={{ width: '100%' }} />
              </div>
              <div className="col-9"><br/><br/>
                <div className="row">Who We are</div>
                <div className="row text-warning">Interiors And Civil Contractors –<br/> Kharghar, Mumbai, Navi Mumbai</div>
              </div>
            </div><br/><br/>
            <div className="row">
              <div className="col-3">                <img src="../images/icon2.png" alt="" style={{ width: '100%' }} />
              </div>
              <div className="col-9"><br/>
                <div className="row">Mission & Values</div>
                <div className="row text-warning">We provide the best brands’<br/> interior concepts.</div>
              </div>
            </div><br/><br/>
            <div className="row">
              <div className="col-3">                <img src="../images/icon3.png" alt="" style={{ width: '100%' }} />
              </div>
              <div className="col-9"><br/>
                <div className="row">Our History</div>
                <div className="row text-warning">Prime renovators & <br/>Interior Designer.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-5">
        <div className="row">
          <div className="col-md-6">
            <h2>Welcome To Prime Renovations And Civil Contractors Kharghar, Navi Mumbai And Kharghar
            </h2>
            <p style={{ fontSize: '20px' }}>We at Prime Renovations are in the field of Interior Designer, Home Renovation, Kitchen Modification, and other related civil construction to make your house a place to live with harmony and pleasure. At Prime Renovations we take all kinds of civil work and interior design that includes flooring tiling, plumbing, and false ceiling work with the best quality materials. We value architectural concepts a lot, so we take care of architectural things while doing any civil and structural alterations.</p>
          </div>
          <div className="col-md-6">
            <p style={{ fontSize: '20px' }}>We provide all solutions for indoor, outdoor, bathroom, and commercial flooring. We suggest flooring according to the room to make it best in view and look as recommended by the customer.

              In our plumbing service, we include the best quality material for piping and fittings. We provide sanitary fitting from the leading brands with the best finishes that will suit interior concepts.

              We always try to introduce new technologies that are going to more comfortable and also help you to have good health and to live in harmony in your dream home.
              <br /><br />
              Founder<br />
              (Mr. Dipendra Singh)</p>
          </div>
        </div>
      </div><br/>
      <div className="container-fluid">
        <div className="row bring_bg  p-5">
          <h1 className='text-center'>OUR WORK PROCESS!</h1>
          <p className='text-center'>Prime renovations -- Civil contractors and interior Renovators in <br/>kharghar,NaviMumbai &Mumbai</p>
          <div className="row">
            <div className="col-md-4 content_count">
              <div className="row"><h4>Share Your Goal</h4></div>
              <div className="row">How all this mistaken our idea,<br/> complete system.</div>
            </div>
            <div className="col-md-4 content_count">
              <div className="row"><h4>Find a Challenges</h4></div>
              <div className="row">Denouncing pleasure praising <br/>expound actual.</div>
            </div>
            <div className="col-md-4 content_count">
              <div className="row"><h4>Get a Solution</h4></div>
              <div className="row">There anyone all love desires<br/> obtain pain itself.</div>
            </div>
          </div>
        </div><br /><br /><br/><br/>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4"><br/>
            <img src="../images/aboutus2.jpg" alt="" style={{ width: '100%', border: '6px solid gray' }} />
          </div>
          <div className="col-md-4">
              <div className="col">
                <h4>85%</h4>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <br/>
                <h4>Quality Assurance</h4>
                
                <p>This mistaken idea of denouncing <br/>pleasure and praising.</p>
              </div>
              <div className="col">
                <h4>96%</h4>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <br/>
                <h4>Process Consulting
                </h4>
                <p>This mistaken idea of denouncing<br/> pleasure and praising.</p>
              </div>
          </div>
          <div className="col-md-4">
            <div className="col">
              <h4>74%</h4>
              <div class="progress">
                <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <br/>
              <h4>Planning & Stratergy</h4>
              <p>This mistaken idea of denouncing <br/>pleasure and praising.</p>
            </div>
            <div className="col">
              <h4>54%</h4>
              <div class="progress">
                <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <br/>
              <h4>Information Security</h4>
              <p>This mistaken idea of denouncing<br/> pleasure and praising.</p>
            </div>
          </div>
        </div>
      </div><br/><br/><br/><br/>
    </>
  )
}

export default Aboutus