import React from 'react'
import { CgAtlasian } from "react-icons/cg";

const Sectionone = () => {
  return (
   <>
    <div className="container-fluid">
        <div className="row bg_image p-5">
            <h2>Prime Renovations </h2>
            <p><CgAtlasian /> Civil Contractor in Kharghar</p>
            <p><CgAtlasian /> Interior Design</p>
            <p><CgAtlasian /> Flooring and Tiles</p>
            <p><CgAtlasian /> Bathroom Design</p>
            <p><CgAtlasian /> Sliding </p>
            <p><CgAtlasian /> Furniture work</p>
        </div>
    </div>
   </>
  )
}

export default Sectionone