import React from 'react';

const SlidingFrameGrillWork = () => {
  return (
    <div className="container">
      {/* Image at the top */}
      <img 
       src="../images/services6.jpg"  
        alt="Prime Renovations Sliding/Frame/Grill Work" 
        className="img-fluid mb-4"
        style={{ width: '100%', height: 'auto', maxWidth: '1200px' }} 
      />

      <h1>Sliding/Frame/Grill Work in Kharghar, Navi Mumbai & Mumbai</h1>
      <p>
        At Prime Renovations, we offer comprehensive home renovation and interior design services, including expert solutions for sliding, frame, and grill work. We aim to make your home a true reflection of your dreams, combining functionality with aesthetic appeal.
      </p>
      <p>
        As one of the top civil contractors in Kharghar, we specialize in interior design, home renovation, and kitchen modification. Our goal is to enhance your living space, making it both harmonious and pleasurable.
      </p>
      <p>
        We provide a range of services, including:
      </p>
      <ul>
        <li>Interior design in Kharghar and Navi Mumbai</li>
        <li>Civil contracting in Kharghar</li>
        <li>Painting work in Mumbai and Thane</li>
      </ul>
      <p>
        If you are considering updating your old window design with new sliding or frame options, we offer the best solutions to match your home's theme. Our sliding and frame window designs are tailored to enhance both the functionality and aesthetics of your space.
      </p>
      <p>
        In addition to window work, we also provide grilling services for doors and windows. Our grilling solutions are designed to improve the security and safety of your home, ensuring peace of mind for you and your family.
      </p>
      <p>
        Contact us today to discuss your sliding, frame, and grill work needs. Our team at Prime Renovations is ready to help you transform your home with high-quality and stylish solutions.
      </p>
      <p>
        Reach out to us at <strong>7666008182</strong> or email us at <strong>dipendrasingh8182@gmail.com</strong>.
      </p>
    </div>
  );
}

export default SlidingFrameGrillWork;
