import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CivilContractor = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-12 text-center">
          <img 
            src="../images/services1.jpg" 
            alt="Civil Contractor" 
            className="img-fluid rounded mb-4" 
            style={{ height: '600px',width: '5000px' }}  // Adjusted height
          />
        </div>
        <div className="col-12">
          <h2 className="text-center">Prime Renovations – Full Home Renovation Work in Kharghar, Navi Mumbai & Mumbai</h2>
          <p>
            At Prime Renovations, we specialize in full home renovation and interior design to make your home the dream haven you’ve always envisioned.
          </p>
          <p>
            As leading civil contractors in Kharghar, Navi Mumbai, and Mumbai, we offer comprehensive services including interior design, home renovation, kitchen modification, and more. We transform your house into a harmonious and pleasurable living space.
          </p>

          <h4>Our Services Include:</h4>
          <div className="row">
            <div className="col-md-6">
              <ul>
                <li>Full Home Renovation</li>
                <li>Interior Design Services</li>
                <li>Kitchen Modification and Setup</li>
                <li>Wooden Work and Furniture</li>
                <li>Modular Kitchen Installation</li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li>Flooring and Tiling</li>
                <li>Plastering and Painting</li>
                <li>False Ceilings</li>
                <li>Bedroom and Window Work</li>
                <li>Grilling for Windows and Doors</li>
              </ul>
            </div>
          </div>

          <h4>Service Areas:</h4>
          <p>
            We operate across Kharghar, Navi Mumbai, and Mumbai, providing top-notch services tailored to your needs. Our team ensures quality, efficiency, and timely completion of every project.
          </p>

          <h4>Why Choose Us?</h4>
          <p>
            Our team of skilled professionals is dedicated to delivering high-quality results. We handle all aspects of your renovation project, from initial planning to final touches, ensuring that every detail meets your expectations.
          </p>
          <p>
            We have transformed over 100 homes in Kharghar, Navi Mumbai, and Mumbai, incorporating modern designs, high-quality materials, and innovative solutions to enhance your living space.
          </p>

          <div className="accordion" id="servicesAccordion">
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  {/*<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Additional Details
                  </button>*/}
                </h5>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#servicesAccordion">
                <div className="card-body">
                  We use advanced technologies and high-quality materials to ensure your home not only looks great but also functions efficiently. Our plumbing services feature top-grade materials and fittings, while our flooring and tiling options are chosen to match your aesthetic preferences.
                </div>
              </div>
            </div>
          </div><br/>

          <h4>Ready to Transform Your Home?</h4>
          <p>
            Contact us today to unlock the doors to your dream home with the best interior designers and civil contractors in Kharghar, Navi Mumbai, and Mumbai.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CivilContractor;
