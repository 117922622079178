import React from 'react';

const PopFalseCeilingWork = () => {
  return (
    <div className="container">
      {/* Image at the top */}
      <img 
        src="../images/gallery1.jpg"
        alt="Prime Renovations POP Work & False Ceiling Work" 
        className="img-fluid mb-4"
        style={{ width: '100%', height: 'auto', maxWidth: '1200px' }} 
      />

      <h1>POP Work & False Ceiling Work in Kharghar, Navi Mumbai & Mumbai</h1>
      <p>
        At Prime Renovations, we specialize in full home renovation and interior design services, including top-notch POP work and false ceiling solutions. Our mission is to transform your home into the ideal space you've always dreamed of.
      </p>
      <p>
        As one of the leading civil contractors in Kharghar, we offer a variety of services to enhance your living environment. Whether it’s interior design, home renovation, kitchen modification, or other related services, we ensure your home is both harmonious and stylish.
      </p>
      <p>
        Our services include:
      </p>
      <ul>
        <li>Interior designers in Kharghar</li>
        <li>Civil contractors in Kharghar</li>
        <li>Painting work in Kharghar</li>
        <li>False ceiling work</li>
        <li>Flooring work in Kharghar</li>
        <li>Tiling work in Kharghar</li>
        <li>Modular kitchen work in Kharghar</li>
      </ul>
      <p>
        We offer exceptional POP work and false ceiling design services to elevate the aesthetic appeal of your living rooms and bedrooms. Our team excels in creating world-class POP and false ceiling solutions tailored to your preferences.
      </p>
      <p>
        Our comprehensive POP and false ceiling services include:
      </p>
      <ul>
        <li>POP false ceiling work</li>
        <li>Decorative gypsum false ceiling service</li>
        <li>POP ceiling work</li>
        <li>Gypsum ceiling work</li>
        <li>False ceiling installation</li>
        <li>Decorative POP false ceiling work</li>
      </ul>
      <p>
        Contact us today to explore how Prime Renovations can enhance your home with our professional POP and false ceiling solutions. We are committed to delivering high-quality work that meets your expectations and adds elegance to your space.
      </p>
      <p>
        Reach out to us at <strong>7666008182</strong> or email us at <strong>dipendrasingh8182@gmail.com</strong>.
      </p>
    </div>
  );
}

export default PopFalseCeilingWork;
