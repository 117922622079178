import React from 'react';

const PaintingWork = () => {
  return (
    <div className="container">
      {/* Image at the top */}
      <img 
        src="../images/services5.jpg" 
        alt="Prime Renovations Painting Work" 
        className="img-fluid mb-4"
        style={{ width: '100%', height: '500px', maxWidth: '1200px' }} 
      />

      <h1>Painting Work in Kharghar, Navi Mumbai & Mumbai</h1>
      <p>
        At Prime Renovations, we specialize in full home renovation and interior design work that transforms your home into a true reflection of your dreams. Our painting services are designed to bring a fresh, vibrant look to your spaces.
      </p>
      <p>
        While painting might seem like a straightforward task, at Prime Renovations, we elevate it to an art form. Our experienced team can create stunning paint designs for your kid’s room, living room, and bedroom, ensuring a world-class finish. We pride ourselves on our specialized and certified team, making us one of the top choices for painting contractors in Kharghar, Navi Mumbai, and Mumbai.
      </p>
      <p>
        With over 15 years of experience, our painting contractors in Kharghar and Mumbai are experts in delivering high-quality results. We handle all types of projects, including exterior and interior painting for houses, offices, public buildings, and industrial spaces. Our goal is to enhance the beauty and functionality of your residential or commercial area.
      </p>
      <p>
        Our painting process includes several key steps to ensure a perfect finish:
      </p>
      <ol>
        <li>
          <strong>Consultation:</strong> Our house painters will meet with you to discuss painting options. We’ll help you choose the best color scheme for your space and provide a detailed proposal with pricing.
        </li>
        <li>
          <strong>Preparation:</strong> We will either hand wash or power wash the area to remove all dirt. Glossy surfaces will be sanded, and any gaps or holes will be repaired with waterproof fillers. Proper preparation ensures a smooth and clean surface for painting.
        </li>
        <li>
          <strong>Painting:</strong> Our team will apply premium quality paints with a uniform finish, following the agreed-upon color scheme.
        </li>
        <li>
          <strong>Cleaning:</strong> After painting, we meticulously clean up any paint chips and ensure the area is left tidy and beautiful. You can inspect the finished work to ensure it meets your expectations.
        </li>
      </ol>
      <p>
        As professional house painting contractors, our main goal is customer satisfaction. Contact us today to discuss painting your dream home or office. We look forward to serving you.
      </p>
      <p>
        Reach out to us at <strong>7666008182</strong> or email us at <strong>dipendrasingh8182@gmail.com</strong>.
      </p>
    </div>
  );
}

export default PaintingWork;
